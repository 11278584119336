<template>
  <div class="container-fluid">
    <template v-if="hasRole('manager')">
      <router-link :to="isCurrentDepartment('iso-progettazione') ? '/manager' : '/check-in'"
        class="btn rounded-btn-custom shadow bg-violet text-white mt-3">
        <div class="d-flex align-items-center">
          <ChevronLeft color="white" class="me-2"/>
          <span class="text-uppercase">Check-in</span>
        </div>
      </router-link>
    </template>

    <Brand :width="110" :height="110" />

    <div class="table-responsive p-0 bg-white rounded-custom mb-2 position-relative" :class="{ 'min-height-custom': !pendingTestWorkQueuePhase.length }">
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <h3 class="text-violet mx-3 mb-0">Totale da assegnare: {{ totalItems }}</h3>
        </div>
        <GlobalSearch
          class="p-4 flex-grow-1"
          :placeholder="'Ricerca per prescrizione...'"
          :debounceTime="1500"
          @toSearch="readValueToSearch"
        />
      </div>

      <table class="table table-bordered m-0">
        <thead class="bg-light-violet text-black">
          <tr>
            <th scope="col">PRESCRIZIONE</th>
            <th scope="col">TIPOLOGIA</th>
            <th scope="col">DATA ETICHETTA</th>
            <th scope="col">PAZIENTE</th>
            <th scope="col">PRESTAZIONE</th>
            <th scope="col">PROVA</th>
            <th scope="col">MATERIALE</th>
            <th scope="col">ELEMENTI</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <div class="position-absolute top-50 start-50 translate-middle">
              <Spinner />
            </div>
          </template>
          <template v-else>
            <template v-if="pendingTestWorkQueuePhase.length">
              <tr v-for="(twqp, index) in pendingTestWorkQueuePhase" :key="index" class="testWorkQueuePhase"
                  :class="[{'selected': idsTestWorkQueuePhaseToAssign.includes(twqp.id), 'hide': twqp.hide}]"
                  @click="toggleSelected(twqp, $event)">
                <td nowrap class="align-middle">
                  <span class="ms-2">{{ twqp.prescriptionInfo.number_text }}</span>
                </td>
                <td nowrap class="align-middle">
                  <span class="ms-2">{{ twqp.isDigital ? 'Digitale' : 'Analogica' }}</span>
                </td>
                <td nowrap class="align-middle">{{ moment(twqp.delivery_date).format("DD/MM/YYYY HH:mm:ss") }}</td>
                <td class="align-middle">{{ twqp.customer }}</td>
                <td class="align-middle text-truncate"
                    style="max-width: 300px;"
                    data-bs-toggle="tooltip" data-bs-placement="top" :title="getPrescriptionRows(twqp.prescription_rows)">
                  {{ getPrescriptionRows(twqp.prescription_rows) }}
                </td>
                <td class="align-middle">{{ twqp.test }}</td>
                <td class="align-middle">{{ twqp.material }}</td>
                <td class="align-middle">{{ twqp.prescriptionInfo.number_elements }}</td>
              </tr>
            </template>
            <template v-else>
              <h4 class="position-absolute top-50 start-50 translate-middle">Nessuna lavorazione presente</h4>
            </template>
          </template>
        </tbody>
      </table>
    </div>

    <template v-if="pendingTestWorkQueuePhase.length">

      <div class="d-flex justify-content-between mt-3">
        <div class="actions">
          <router-link to="/manager" class="btn rounded-btn-custom shadow bg-violet text-white">
            <div class="d-flex align-items-center">
              <ChevronLeft color="white" class="me-2"/>
              <span class="text-uppercase">Manager</span>
            </div>
          </router-link>
          <button type="button" class="btn btn-violet text-uppercase justify-content-start ms-2"
                  :disabled="!idsTestWorkQueuePhaseToAssign.length"
                  @click="showTechnicianModal()">
            {{ getLabel() }}
          </button>
        </div>

        <Pagination
          :total-pages="totalPages"
          :page-number="pageNumber"
          :array="idsTestWorkQueuePhaseToAssign"
          @increment="() => pageNumber++"
          @decrement="() => pageNumber--"
        />

      </div>

    </template>

  </div>

  <Empty v-if="techniciansModal"
         :size="'md'"
         :modal_id="'techniciansModal'"
         @hidden="closeTechnicianModal">
    <SpecialActions
      style="height: 400px;"
      :title="technicianModalTitle"
      :placeholderNameSelect="'seleziona il tecnico...'"
      :optionsSelect="technicians"
      :notes="false"
      @selected="technicianIdSelected"
      @clear="clearTechnicianIdSelected"
      @store="save"
      @close="closeTechnicianModal"
    />
  </Empty>

</template>

<script>
import GlobalSearch from "@/components/GlobalSearch";
import {
  computed,
  defineComponent,
  getCurrentInstance, inject,
  onMounted,
  onUnmounted,
  ref,
  toRaw,
  watch
} from "vue";
import { pending as fetchPendingTestWorkQueuePhase } from "@/use/repositories/testWorkQueuePhase/pending";
import { fetchById as fetchTestWorkQueueById } from "@/use/repositories/testWorkQueuePhase/fetchById";
import {hideModal as closeModal} from '@/use/modal/hide';
import {fetchByDepartmentSlug} from "@/use/repositories/users/fetchByDepartmentSlug";
import Empty from "@/components/Modal/Empty";
import Spinner from "@/components/general/Spinner";
import SpecialActions from "@/components/SpecialActions";
import {useStore} from "vuex";
import moment from 'moment';
import Brand from "@/components/Brand";
import _ from 'lodash';
import {update as updateTestWorkQueuePhase} from "@/use/repositories/testWorkQueuePhase/update";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";
import {useI18n} from "vue-i18n";
import {info} from '@/use/toast/info'
import {success} from "@/use/toast/success";
import ChevronLeft from "@/components/Icons/ChevronLeft";
import Pagination from "@/components/general/Pagination.vue";
import { hasRole } from "@/use/utilities/users/hasRole";

export default defineComponent({
  name: "App",
  components: {
    Pagination,
    GlobalSearch,
    ChevronLeft,
    Brand,
    Empty,
    SpecialActions,
    Spinner,
  },
  setup() {
    const { states } = inject("constants");
    const pendingTestWorkQueuePhase = ref([]);
    const pendingTestWorkQueuePhaseSelected = computed(() => {
      return pendingTestWorkQueuePhase.value.filter((twq) => {
        return idsTestWorkQueuePhaseToAssign.value.includes(twq.id);
      });
    });
    const idsTestWorkQueuePhaseToAssign = ref([]);
    const techniciansModal = ref(false);
    const technicians = ref([]);
    const technicianId = ref(null);
    const technicianModalTitle = computed(() => {
      let title = 'Seleziona il tecnico al quale assegnare ';
      if (idsTestWorkQueuePhaseToAssign.value.length === 1) {
        title += 'la lavorazione';
      } else {
        title += 'le lavorazioni';
      }
      return title;
    });
    const store = useStore();
    const pageNumber = ref(1);
    const totalPages = ref(null);
    const i18n = useI18n();
    let lastDeliveryDate = null;
    const loading = ref(true);
    const searchKeyword = ref(null);
    const totalItems = ref(0);

    // Pusher.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const subscribe = (channelName, eventName) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      const channel = pusher.subscribe(channelName);
      channel.bind("pusher:subscription_succeeded", () => console.log("subscription succeeded"));
      channel.bind(eventName, async (event) => {
        console.log("event received", event);
        let eventStateSlug = event.state.slug;
        // Logs.
        console.log(`EVENT DATE: ${moment(event.test_work_queue.delivery_date)}`);
        console.log(`LAST DELIVERY DATE: ${lastDeliveryDate}`);
        // Push test work queue phase only if the date is less than the last record.
        if (moment(event.test_work_queue.delivery_date).isBefore(lastDeliveryDate)) {
          let eventTestWorkQueueId = event.id;
          fetchTestWorkQueueById(eventTestWorkQueueId, 'with_pending', false).then((response) => {
            let testWorkQueuePhase = createTestWorkQueuePhasePendingObj(response.testWorkQueuePhase);
            // Checks states.
            if (eventStateSlug === states.PENDING) {
              // Remove last test work queue.
              pendingTestWorkQueuePhase.value.pop();
              // Push event.
              pendingTestWorkQueuePhase.value.push(testWorkQueuePhase);
              // Reorder by delivery date.
              pendingTestWorkQueuePhase.value = _.orderBy(toRaw(pendingTestWorkQueuePhase.value), 'delivery_date');
              // notifications.
              info(i18n.t('A new processing has arrived!'));
              setTimeout(() => {
                success(i18n.t('Reordering successful!'));
                success(i18n.t('New workings successfully recovered!'));
              }, 1000);
            } else {
              // Find index test work queue phase.
              let index = pendingTestWorkQueuePhase.value.findIndex((twqp) => parseInt(twqp.id) === testWorkQueuePhase.id);
              if (index !== -1) {
                // Set disabled.
                pendingTestWorkQueuePhase.value[index].hide = true;
                // notifications.
                info(i18n.t('Job has changed status!'));
              }
            }
          });
        }
      });
    };
    const unsubscribeChannel = (channelName) => {
      console.log(`unsubscribing from "${channelName}"...`, { $pusher: pusher });
      console.log("unsubscribing...");
      pusher.unsubscribe(channelName);
    };

    onMounted(() => {
      // Subscribe to pusher channel.
      subscribe(`presence-user.${store.state.user.id}`, 'manager_change_phase_state');
      getData();
    });

    onUnmounted(() => {
      console.log("unmount");
      unsubscribeChannel("manager_change_phase_state");
    });

    watch(() => pageNumber.value, (value) => {
      getData(value);
    });

    watch(() => searchKeyword.value, () => {
      getData(pageNumber.value);
    });

    const readValueToSearch = (value) => {
      searchKeyword.value = value;
    }

    const getData = (pageNumber) => {
      // Show loader
      loading.value = true;

      // Reset pending array
      pendingTestWorkQueuePhase.value = [];

      let data = {
        department_slug: store.state.mainRole.team.name,
        page: pageNumber,
      };

      // Attach keyword on request
      if (searchKeyword.value) {
        data = {...data, keyword: searchKeyword.value};
      }

      clearAssigned();
      fetchPendingTestWorkQueuePhase(data, false).then((response) => {
        totalPages.value = response.pendingTestWorkQueuePhases.meta.last_page;
        totalItems.value = response.pendingTestWorkQueuePhases.meta.total;
        if (response.pendingTestWorkQueuePhases.data.length) {
          pendingTestWorkQueuePhase.value = transformData(response.pendingTestWorkQueuePhases.data);
          updateLastDeliveryDate();
        }
        fetchByDepartmentSlug(store.state.mainRole.team.name).then((response) => {
          technicians.value = response.usersDepartment.users.map((user) => {
            return {
              value: user.id,
              label: `${user.first_name} ${user.last_name}`,
            };
          });
        });
      })
      .finally(() => {
        // Hide loader
        loading.value = false;
      })
    }

    const updateLastDeliveryDate = () => {
      let lastIndex = (pendingTestWorkQueuePhase.value.length - 1);
      lastDeliveryDate = moment(pendingTestWorkQueuePhase.value[lastIndex].delivery_date);
    }

    const transformData = (data) => {
      return data.map((testWorkQueuePhase) => {
        return createTestWorkQueuePhasePendingObj(testWorkQueuePhase);
      });
    }

    const createTestWorkQueuePhasePendingObj = (testWorkQueuePhase) => {
      return {
        id: testWorkQueuePhase.id,
        prescriptionInfo: {
          number_text: testWorkQueuePhase.test_work_queue.prescription_test.prescription.number_text,
          number_elements: testWorkQueuePhase.test_work_queue.prescription_test.prescription.prescription_rows_count,
        },
        isDigital: typeof testWorkQueuePhase.test_work_queue.prescription_test.intraoral_scanner_id === 'number',
        delivery_date: testWorkQueuePhase.test_work_queue.delivery_date,
        customer: `${testWorkQueuePhase.test_work_queue.prescription_test.prescription.customer.first_name} ${testWorkQueuePhase.test_work_queue.prescription_test.prescription.customer.last_name}`,
        test: testWorkQueuePhase.test_work_queue.prescription_test.test.name,
        prescription_rows: testWorkQueuePhase.test_work_queue.prescription_test.prescription.care_plan_rows,
        material: testWorkQueuePhase.test_work_queue.prescription_test.prescription.material,
        hide: false,
      }
    }

    const getPrescriptionRows = (prescriptionRows) => {
      let array = [];
      prescriptionRows.forEach((el) => {
        array.push(el.name);
      });
      return array.join(' / ');
    }

    const getLabel = () => {
      if (idsTestWorkQueuePhaseToAssign.value.length === 1) {
        return 'assegna la casella selezionata';
      }
      return 'assegna le caselle selezionate';
    }

    const toggleSelected = (twqp, event) => {
      // Find parent element.
      let element = event.target.closest('.testWorkQueuePhase');
      // Toggle selected class.
      element.classList.toggle("selected");
      // Toggle id.
      idsTestWorkQueuePhaseToAssign.value = _.xor(toRaw(idsTestWorkQueuePhaseToAssign.value), [twqp.id]);
    }

    // Close technician modal.
    const closeTechnicianModal = () => {
      closeModal('techniciansModal');
      setTimeout(() => {
        techniciansModal.value = false;
      }, 500);
    }
    // Read technician selected.
    const technicianIdSelected = (id) => {
      technicianId.value = id;
    }
    // Clear technician selected.
    const clearTechnicianIdSelected = () => {
      technicianId.value = null;
    }
    const showTechnicianModal = () => {
      techniciansModal.value = true;
    }
    // Save.
    const save = () => {
      updateTestWorkQueuePhase({
        source_ids: idsTestWorkQueuePhaseToAssign.value,
        user_id: technicianId.value,
        action: "attach_user",
      })
      .then(() => {
        setTimeout(() => success(i18n.t('Assignment was successful!')), 2000);
        closeTechnicianModal();
        syncData();
      });
    }

    const clearAssigned = () => {
      idsTestWorkQueuePhaseToAssign.value = [];
    }

    const syncData = () => {
      getData();
    }

    return {
      pendingTestWorkQueuePhase,
      moment,
      getPrescriptionRows,
      idsTestWorkQueuePhaseToAssign,
      techniciansModal,
      closeTechnicianModal,
      technicianModalTitle,
      technicianIdSelected,
      clearTechnicianIdSelected,
      save,
      pageNumber,
      totalPages,
      toggleSelected,
      showTechnicianModal,
      technicians,
      getLabel,
      pendingTestWorkQueuePhaseSelected,
      loading,
      readValueToSearch,
      totalItems,
      isCurrentDepartment,
      hasRole
    };
  },
});
</script>

<style lang="scss" scoped>
  @import "../../scss/abstracts/_variables.scss";
  .hide {
    opacity: 0.2;
    pointer-events: none;
  }
 .testWorkQueuePhase:hover {
   cursor: pointer;
 }
 .selected {
   background-color: $light_violet;
 }
 .min-height-custom {
   height: 740px;
 }
</style>
