<template>
  <div ref="conversationContainer" class="conversation__container w-100 h-100">

    <template v-if="loading">
      <Spinner />
    </template>

    <template v-else>
      <div class="conversation overflow-auto" :style="{'fontSize': fontSize + 'rem'}">

        <!-- Conversation -->
        <div ref="conversation" class="conversation overflow-auto" :style="{'fontSize': fontSize + 'rem'}">
          <div v-if="messages.length > 0" class="conversation__messages my-3">
            <template v-for="(messageGroup, index) in messages" :key="index">
              <div class="conversation__message__container__date d-flex justify-content-center align-items-center">
                <div class="conversation__message__date d-flex justify-content-center p-2 shadow">{{ messageGroup.date }}</div>
              </div>
              <div class="conversation__message__container d-flex flex-column w-100" :class="getPositionMessage(message)"
                   v-for="(message, index) in messageGroup.messages" :key="index">
                <div class="conversation__message shadow d-flex flex-column w-70"
                    :class="{'text-light': message.channel_message_type !== 'default'}"
                    :style="{ 'backgroundColor': message.channel_message_color }">
                  <!-- Message -->
                  <div class="conversation__message__user fw-bold mb-2">
                    {{ message.first_name }} {{ message.last_name }}
                    <span class="badge badge-pill bg-dark-violet ms-1">{{ message.role }}</span>
                  </div>
                  <!-- Media -->
                  <template v-if="message.attachments.length">
                    <template v-for="(url, index) in message.attachments" :key="index">
                      <img @click="previewImage(message, index)" class="media mb-2" width="100" :src="url" alt="img">
                    </template>
                  </template>
                  <div v-if="message.text !== ''" class="conversation__message__text">{{ parsedMessageBody(message.text) }}</div>
                  <div class="conversation__message__time text-end">
                    {{ message.time }}
                  </div>
                </div>
              </div>
            </template>

            <Spinner v-if="loaderConversation" />

          </div>

          <div v-else>
            <div class="chat d-flex flex-column align-items-center p-3 overflow-auto h-100 w-100">
              <span>Nessun messaggio</span>
            </div>
          </div>

        </div>

        <!-- Chat Input -->
          <div ref="conversationFooter" class="conversation__footer w-100 position-absolute bottom-0 rounded-only-bottom">
            <div class="conversation__footer__actions w-100 h-100 d-flex justify-content-evenly align-items-center">
              <Accordion id="chat_accordion" :collapsed="true" :showArrow="false" class="mx-1">
                <template v-slot:header>
                  <Plus style="margin: -10px" />
                </template>
                <template v-slot:body>
                  <div class="d-flex flex-column">
                    <button @click="() => showAttachmentsModal = !showAttachmentsModal"
                      class="btn my-3" data-bs-toggle="tooltip" data-bs-placement="right" title="Allega da questo dispositivo">
                      <img src="./icons/paperClip.svg" alt="paperclip" width="30">
                    </button>
                    <button @click="showQrcodeModal = true;"
                      class="btn my-3" data-bs-toggle="tooltip" data-bs-placement="right" title="Allega da smartphone">
                      <img src="../../assets/icons/chat/qr-code.svg" alt="qr-code" width="30">
                    </button>
                    <button @click="toFavorite()" type="button"
                      class="btn my-3" data-bs-toggle="tooltip" data-bs-placement="right" :title="`${isFavorite ? 'Rimuovi chat da' : 'Aggiunti chat a'} preferiti`"
                      >
                      <img :src="require(`@/assets/icons/${isFavorite ? 'heart-selected.svg' : 'heart.svg'}`)" width="30" alt="arrow-left" class="cp" >
                    </button>
                  </div>
                </template>
              </Accordion>
              <span
                class="form-control m-1 align-self-baseline textarea"
                role="textbox"
                contenteditable
                :disabled="sendingMessage"
                @input="(event) => message = event.target.innerText"
                ref="messageInput"
                >
              </span>
              <button
                :disabled="sendingMessage || (message === '' && media === '')"
                :class="{ 'opacity': (message === '' && media === '') }"
                class="align-self-baseline"
                @click="storeMessage()"
              >
                <img class="conversation__footer__input__icon__send mx-1" src="./icons/sendFill.svg" alt="send" width="30">
              </button>
            </div>
          </div>

        <!-- Notify new message not mine arrived -->
        <template v-if="badgeNewMessageArrived">
          <small @click="scrollToBottom()"
                 class=" conversation__message__badge__new__message text-white text-center position-absolute w-100 text-uppercase"
                 :style="{ 'backgroundColor': colorBadge }"
                 style="bottom: 60px;">
            nuovo messaggio
            <img class="conversation__message__badge__icon__caretdown" src="@/assets/icons/caret-down.svg" width="20" alt="caret-down">
          </small>
        </template>

      </div>
    </template>
  </div>
  <!-- Modal -->
    <Modal v-if="showQrcodeModal"
           class="d-flex"
           :size="'md'"
           :modal_id="'qrcode'"
           :title="'Scansiona il QR'"
           :subtitle="'Scansionando il QR potrai caricare i files direttamente dal cellulare'"
           :decline_label="'chiudi'"
           :lightLayout="true"
           @hidden="closeQrcodeModal"
    >
      <div class="d-flex flex-column justify-content-center align-items-center">
        <!-- Qrcode -->
        <QRCode :text="externalDeviceRoute" />
      </div>
    </Modal>

    <Modal
      v-if="showAttachmentsModal"
      size="lg"
      modal_id="attachmentsModal"
      @hidden="closeAttachmentsModal"
      :decline_label="'chiudi'"
      >
        <div class="col-12 card shadow p-5">
          <Dropzone
            :upload-through-qrcode="true"
            :max-files="10"
            :upload="isUploadingAttachment" 
            :assign-url-s3="assignUrlChat" 
            @increment="mediaCounter++"
            @decrease="mediaCounter--"
            @s3-url-upload-completed="handleAttachmentsCompleted"
          />
            <button
              class="btn btn-violet text-uppercase mt-4 p-2"
              @click="isUploadingAttachment = true"
              :disabled="mediaCounter === 0"
            >
              conferma
            </button>
        </div>
  </Modal>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive, ref, watch, watchEffect } from "vue";
import store from "@/store";
import moment from "moment/moment";
import _ from "lodash";
import Modal from '@/components/Modal/Standard';
import QRCode from '@/components/QRCode.vue';
import Accordion from "@/components/general/Accordion";
import Plus from "@/components/Icons/Plus";
import Dropzone from "@/components/upload/Dropzone";
import { Collapse } from "bootstrap";

import {show as showMessage} from "@/use/repositories/chat/message/show";
import { edit as editChannel } from "@/use/repositories/chat/channel/edit";
import { show as showChannel } from "@/use/repositories/chat/channel/show";
import {fetchAll as fetchAllMessages} from "@/use/repositories/chat/message/fetchAll";
import { store as storeMessageChat } from "@/use/repositories/chat/message/store";
import { hideModal as closeModal } from "@/use/modal/hide";
import getAuthToken from "@/use/get-auth-token";
import { uuid } from '@/use/utilities/uuid';
import { assignUrl as assignUrlChat } from "@/use/repositories/chat/media/assignUrl";

import Spinner from "@/components/general/Spinner";
import {api as viewerApi} from "v-viewer";

export default {
  name: "Conversation",
  components: {
    Spinner,
    QRCode,
    Modal,
    Accordion,
    Plus,
    Dropzone
  },
  props: {
    fontSize: {
      type: Number,
      required: false,
      default: 0.9,
    },
    bgColor: {
      type: String,
      required: false,
      default: '#FFFFFF',
    },
    colorBadge: {
      type: String,
      required: false,
      default: '#725D82',
    },
    prescription: {
      type: Object,
      required: true,
    },
    tabActiveType: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const message = ref("");
    const media = ref("");
    const sendingMessage = ref(false);
    const chatChannelId = ref(null);
    const messages = ref([]);
    const loading = ref(true);
    // Attachments
    const showAttachmentsModal = ref(false);
    const mediaCounter = ref(0);
    const isUploadingAttachment = ref(false);

    const conversation = ref(null);
    const totalPages = ref(1);
    const page = ref(1);
    const isFavorite = ref(false);
    const existsHistory = computed(() => {
      return (page.value < totalPages.value && totalPages.value > 1);
    });
    const parsedMessageBody = (body) => (body.replace(/\\"(?=\?)/g, '"'));

    const onTheTop = ref(null);
    const onTheBottom = ref(null);
    const badgeNewMessageArrived = ref(false);
    const messageArrivedIsMine = ref(true);
    const isHistoryMessages = ref(false);
    const haveScroll = ref(null);
    const loaderConversation = ref(false);
    const conversationFooter = ref(null);
    const conversationContainer = ref(null);
    // QR
    const showQrcodeModal = ref(false);
    const qrData = reactive({
      files: [],
    });

    const uniqueQrUuid = uuid();

    const closeQrcodeModal = async () => {
      await closeModal('qrcode');
      showQrcodeModal.value = false;
    }

    const externalDeviceRoute = `${window.location.origin}/external/device/chat/${uniqueQrUuid}?signature=${getAuthToken()}`;
    // Pusher instance.
    const internalInstance = getCurrentInstance();
    const pusher = internalInstance.appContext.config.globalProperties.$pusher;
    const messageInput = ref(null);

    onMounted(() => {
      // Subscribe to channel.
      subscribe(`presence-user.${store.state.user.id}`, message);
      subscribe("private-live", `custom_event_${uniqueQrUuid}`);
      // Fetch messages.
      if (typeof props.prescription.chat_channel === "object" && props.prescription.chat_channel !== null) {
        console.log("CHANNEL EXISTS");
        chatChannelId.value = props.prescription.chat_channel.id;
        console.log(`PRESCRIPTION CHANNEL ID: ${chatChannelId.value}`);
        getMessages(chatChannelId.value);
        showChannel(chatChannelId.value, 'user_extra').then(response => isFavorite.value = response.channel?.meta?.is_favorite);
      } else {
        console.log("CHANNEL NOT EXISTS");
        chatChannelId.value = null;
        loading.value = false;
      }
    });

    watch(() => messages.value, () => {
      if (onTheBottom.value) {
        scrollToBottom();
      } else {
        if (messageArrivedIsMine.value && !isHistoryMessages.value) {
          // Message arrived is mine and scroll to bottom.
          scrollToBottom();
        } else if (!isHistoryMessages.value) {
          // Message arrived is not mine and show notify.
          badgeNewMessageArrived.value = true;
        }
      }
    }, { deep: true });

    // Is on th bottom.
    watch(() => onTheBottom.value, (value) => {
      if (value) {
        scrollToBottom();
      }
    });

    // Is on the top.
    watch(() => onTheTop.value, (value) => {
      if (value) {
        getHistory();
      }
    });

    watchEffect(() => {
      // Infinite scroll init.
      if (conversation.value) {
        conversation.value.addEventListener("scroll", handleScroll);
      }
    });

    const handleScroll = () => {
      onTheTop.value = (conversation.value.scrollTop === 0);
      onTheBottom.value = ((conversation.value.scrollHeight - conversation.value.scrollTop) === conversation.value.clientHeight);
      haveScroll.value = (conversation.value.clientHeight < conversation.value.scrollHeight);
    }

    const parseCustomEvent = (event) => {
      console.log("parse custom event message");
      event.data.forEach((mediaName) => {
        qrData.files.push(mediaName);
      });

      storeMessage();
    }

    const closeAttachmentsModal = async() => {
      await closeModal('attachmentsModal');
      showAttachmentsModal.value = false;
    }

    const handleAttachmentsCompleted = (files) => {
      files.forEach((mediaName) => {
        qrData.files.push(mediaName);
      });
      
      saveQrCodeData();
      closeAttachmentsModal();

      // Close collapsed actions
      const myCollapse = document.getElementById('panelsStayOpen-collapse-chat_accordion');
      const collapse = Collapse.getInstance(myCollapse);
      collapse.toggle();

      isUploadingAttachment.value = false;
    }

    const saveQrCodeData = () => {
      const formData = createFormData();
      formData.set("action", "with_media");

      qrData.files.forEach((mediaName) => {
        formData.append("file_names[]", mediaName);
      });

      storeMessageChat(formData);
      clearMessageField();
      scrollToBottom();
      qrData.files = [];
    }

    const subscribe = (channelName, message) => {
      console.log(`subscribing from "${channelName}"...`, { $pusher: pusher });
      let channel = pusher.subscribe(channelName);
      const callback = (eventName, data) => {
        console.log(eventName);
        switch (eventName) {
          case "pusher:subscription_succeeded":
            console.log("subscription succeeded");
            break;
          case "messaging":
            messageArrivedIsMine.value = (data.user_id === store.state.user.id);
            if (chatChannelId.value === data.chat_channel_id && store.state.user.roles.includes('manager')) {
              isHistoryMessages.value = false;
              findMessageById(data.id);
            } else {
              if (chatChannelId.value !== data.chat_channel_id) {
                // Show red dot on chat button.
                store.dispatch("setBadge", true);
              }
            }
            break;
          case `custom_event_${uniqueQrUuid}`:
            parseCustomEvent(data);
            break;
        }

        if (qrData.files.length && chatChannelId.value != null) {
          saveQrCodeData();
          closeQrcodeModal();
        }

        console.log(`bind global channel: The event ${eventName} was triggered with data ${JSON.stringify(data)}`);
      };
      // bind to all events on the channel
      channel.bind_global(callback, message);
    };

    const getMessages = async (id, history = false, initialHeight = null) => {
      fetchAllMessages({
        action: "by_channel",
        channel_id: id,
        page: page.value,
      }).then((response) => {
        if (history) {
          response.data.data.forEach((message) => {
            syncMessage(message, history);
          });
        } else {
          messages.value = _(response.data.data)
            .map((value) => {
              let msg = transformDataMessage(value);
              return {
                ...msg,
                date: moment(value.created_at).format("DD/MM/YYYY"),
              };
            })
            .groupBy('date')
            .map((value, key) => ({ date: key, messages: _.chain(value)
                .sortBy('created_at')
                .value()
                .reverse()
            }))
            .value()
            .reverse();
        }
        // Hide spinner.
        loading.value = false;
        // Set total pages.
        totalPages.value = response.data.meta.last_page;
        // Set effect on infinite scroll.
        if (initialHeight !== null) {
          setTimeout(() => {
            console.log(conversationFooter.value.scrollHeight);
            conversation.value.scrollTop = (conversation.value.scrollHeight - initialHeight) - conversationFooter.value.scrollHeight;
          }, 1);
        }
      });
    };

    const transformDataMessage = (message) => {
      return {
        first_name: message.user.first_name,
        last_name: message.user.last_name,
        channel_message_type: message.message_type.slug,
        channel_message_color: message.message_type.color,
        is_sender: (store.state.user.id === message.user_id),
        time: moment(message.created_at).format("HH:mm"),
        text: renderMessage(message.body),
        attachments: getThumbnails(message),
        role: message?.user_role?.name,
      }
    }
    const renderMessage = (string) => {
      return string.replace(String.fromCharCode(92), String.fromCharCode('', ''));
    }
    const findMessageById = (id) => {
      showMessage(id).then((response) => {
        syncMessage(response.message);
      });
    }
    const syncMessage = (data, history = false) => {
      let createdAt = moment(data.created_at).format("DD/MM/YYYY");
      let formattedMessage = transformDataMessage(data);
      // Find date and push.
      let index = messages.value.findIndex(item => {
        return item.date === createdAt;
      });
      if (index === -1) {
        let data = {
          date: createdAt,
          messages: [formattedMessage],
        };
        // If the date doesn't exist create the group and push message.
        if (history) {
          messages.value.unshift(data);
        } else {
          messages.value.push(data);
        }
      } else {
        // If date exist push message.
        if (history) {
          messages.value[index].messages.unshift(formattedMessage);
        } else {
          messages.value[index].messages.push(formattedMessage);
        }
      }
      // Hide loader.
      loaderConversation.value = false;
      enabledInputMessage();
    }
    const getPositionMessage = (message) => {
      return (message.is_sender ? 'end' : 'start');
    };

    const getThumbnails = (message) => {
      let urls = [];
      message.media.forEach((singleMedia) => {
        urls.push(singleMedia.temporary_url);
      })
      return urls;
    };

    const storeMessage = () => {
      // Check if exists data to store.
      if ((message.value.trim().length === 0 && qrData.files.length === 0)) {
        return;
      }

      // Enabled loader.
      loaderConversation.value = true;
      disabledInputMessage();
      let formData;
      if (chatChannelId.value === null) {
        console.warn("Channel doesnt exists...");
      } else {
        scrollToBottom();
        formData = createFormData();
        save(formData);
      }
    }

    const save = (formData) => {
      // Store message.
      if (message.value.trim().length !== 0) {
        // Store without media.
        storeMessageChat(formData).then(() => {
          console.log("store message");
          clearMessageField();
          scrollToBottom();
        });
      }
    }

    const clearMessageField = () => {
      message.value = "";
      messageInput.value.innerText = "";
    }

    const enabledInputMessage = () => {
      sendingMessage.value = false;
    }

    const disabledInputMessage = () => {
      sendingMessage.value = true;
    }

    const createFormData = () => {
      let formData = new FormData();
      formData.append("chat_channel_id", chatChannelId.value);
      formData.append("type_slug", "default");
      formData.append("message_subcategory_slug", "free-message");
      if (message.value !== "") {
        formData.append("body", message.value);
      }

      formData.append("action", "without_media");

      // Display the key/value pairs
      for (let pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]);
      }
      return formData;
    }

    const scrollToBottom = (millisecond = 500) => {
      setTimeout(() => {
        let el = conversation.value;
        if (el) {
          let scrollHeight = el.scrollHeight;
          el.scroll({ top: (scrollHeight), behavior: 'smooth' });
          // Hide badge new message if showed.
          if (badgeNewMessageArrived.value) {
            badgeNewMessageArrived.value = false;
          }
        }
      }, millisecond);
    };

    const getHistory = () => {
      if (existsHistory.value) {
        isHistoryMessages.value = true;
        page.value++;
        // Store current scroll height for later restore.
        let initialHeight = conversation.value.scrollHeight;
        // Get history messages.
        getMessages(chatChannelId.value, true, initialHeight);
      }
    }

    const previewImage = (message, index) => {
      viewerApi({
        images: message.attachments.map((url) => {
          return {
            "data-source": url,
          }
        }),
        options: {
          toolbar: true,
          url: "data-source",
          initialViewIndex: index,
        },
      });
    }

    const toFavorite = () => {
      const value = !isFavorite.value;
      editChannel(chatChannelId.value, 'sync_favorite', value).then(() => isFavorite.value = value);
    }

    return {
      getPositionMessage,
      getThumbnails,
      message,
      messages,
      scrollToBottom,
      loading,
      media,
      storeMessage,
      conversation,
      totalPages,
      onTheTop,
      onTheBottom,
      badgeNewMessageArrived,
      page,
      haveScroll,
      getHistory,
      loaderConversation,
      previewImage,
      sendingMessage,
      conversationFooter,
      conversationContainer,
      showQrcodeModal,
      externalDeviceRoute,
      closeQrcodeModal,
      chatChannelId,
      parsedMessageBody,
      toFavorite,
      isFavorite,
      messageInput,
      showAttachmentsModal,
      closeAttachmentsModal,
      mediaCounter,
      assignUrlChat,
      isUploadingAttachment,
      handleAttachmentsCompleted,
    }
  }
}
</script>

<style scoped lang="scss">
.conversation {
  height: calc(100% - 50px);
}
.conversation__footer {
  padding: 0.5rem;
  background-color: #f5f5f5;
}
.conversation__footer__actions > input {
  margin: 0 10px;
  border-radius: 10px;
}
.conversation__message__badge__new__message {
  bottom: 60px;
  padding: 5px;
}
.conversation__message__badge__icon__caretdown {
  &:hover {
    cursor: pointer;
  }
}
.end {
  align-items: end;
}
.start {
  align-items: start;
}
.conversation__message {
  border-radius: 10px;
  margin: 1rem 1.5rem;
  padding: .5rem;
  white-space: pre-line;
}
button {
  border: 0;
  padding: 0;
  background: unset;
}
.conversation__message__user {
  display: flex;
  align-items: center;
}
.conversation__message__user,
.conversation__message__time {
  font-size: 10px;
}
.conversation__message__text,
.conversation__message__date {
  font-size: 12px;
}
.media:hover {
  cursor: pointer;
}
.info {
  background-color: #138496!important;
  color: white!important;
}
.warning {
  background-color: #DD7230 !important;
  color: white !important;
}
.alert {
  background-color: rgb(233, 123, 134) !important;
  color: white !important;
}
.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}
.textarea::before {
  content: 'Scrivi un messaggio';
  color: #999;
}

.textarea:not(:empty)::before {
  content: '';
}
</style>
