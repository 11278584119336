<script>
import { ref, onMounted, onBeforeMount, onUnmounted, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getUserData } from "@/use/getUserData";
import { redirectTo } from "@/use/repositories/redirectTo";
import { updateMainDepartment } from "@/use/repositories/users/updateMainDepartment";

import _find from "lodash/find";
import _filter from "lodash/filter";

import Brand from "@/components/Brand";
import AppWizard from "@/components/LoginWizard/AppWizard.vue";
import MultiSelectionCard from "@/components/LoginWizard/MultiSelectionCard.vue";

export default {
  name: "LoginChoose",
  components: {
    Brand,
    AppWizard,
    MultiSelectionCard
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const roles = ref([]);
    const departments = ref([]);
    const department = ref(null);
    const role = ref(null);
    const disable = ref(true);
    const currentStep = ref(null);
    const steps = reactive([
      {
        id: 0,
        title: 'Ruolo',
        completed: false,
        icon_path: "user.svg"
      },
      {
        id: 1,
        title: 'Reparto',
        completed: false,
        icon_path: "department.svg"
      },
      {
        id: 2,
        title: 'Invia',
        completed: false,
        icon_path: "check-circle.svg"
      }
    ]);

    // Prevent browser back button.
    onBeforeMount(() => {
      history.pushState(null, null, location.href);
      window.onpopstate = () => history.go(1);
    })

    // Revert browser overwrite.
    onUnmounted(() => {
      window.onpopstate = () => history.go(0);
    })

    onMounted(() => {
      store.state.user.roles.forEach(roleSlug => {
        const role = _find(store.state.userData.map(item => ({ ...item.role, 'selected': false })), (role) => role.slug === roleSlug);
        if (role) roles.value.push(role);
      });
    });

    watch(() => currentStep.value?.currentStep, () => {
      disable.value = true;
      checkInteraction();
    }, { deep: true });

    const checkInteraction = () => {
      switch (currentStep.value.currentStep) {
        case 0:
          disable.value = !roles.value.some(role => role.selected);
          break;
        case 1:
          disable.value = !departments.value.some(dep => dep.selected);
          break;
        case 2:
          disable.value = true;
          break;
      }
    }

    const chooseRole = async (roleSelected) => {
      const selectedRole = _find(roles.value, (role) => role.selected == true);
      if (selectedRole) selectedRole.selected = false;

      const newSelectedRole = _find(roles.value, (role) => roleSelected.id == role.id);
      if (newSelectedRole) newSelectedRole.selected = true;

      await store.dispatch("setMainRole", _find(store.state.userData, (item => item.role.slug == roleSelected.slug)));

      const userData = await getUserData();
      await store.dispatch("setUserDepartments", _filter(userData.departments, item => item.role_slug == store.state.mainRole.role.slug));

      departments.value = store.state.user.departments.map(
          item => ({ ...item, 'selected': false })), role.value = roleSelected;
      checkInteraction();
    }

    const chooseDepartment = async (departmentSelected) => {
      if (_find(departments.value, (department) => department.selected == true))
        _find(departments.value, (department) => department.selected == true).selected = false;

      _find(departments.value, (department) => departmentSelected.id == department.id).selected = true;
      department.value = departmentSelected;

      checkInteraction();
    }

    const choose = (value) => {
      switch (currentStep.value.currentStep) {
        case 0:
          chooseRole(value);
          break;
        case 1:
          chooseDepartment(value);
          break;
      }
    }

    const submit = async () => {
      await updateMainDepartment(department.value.team_id, store.state.mainRole.role_id);
      await redirectTo(store.state.user.roles, store.state.user.departments, store).then(async (response) => {
        await router.push({ name: response.routeName });
      });
    }

    const filteredRoles = computed(() => {
      return roles.value.filter((item) => item.slug !== 'admin');
    })


    return { choose, submit, roles, role, department, departments, steps, disable, currentStep, filteredRoles }
  }
}
</script>

<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-center">
      <brand :width="120" :height="120" />
    </div>
    <div class="container">
      <AppWizard :steps="steps" :disable="disable" ref="currentStep" @submit="submit">
        <template v-slot:step-0>
          <h1 class="text-center">Scegli il ruolo</h1>
          <MultiSelectionCard :data="filteredRoles" @choose="choose" />
        </template>
        <template v-slot:step-1>
          <h1 class="text-center">Scegli il dipartimento</h1>
          <MultiSelectionCard :data="departments" @choose="choose" />
        </template>
        <template v-slot:step-2>
          <h1 class="text-center mb-5">Conferma la selezione</h1>
          <div class="d-flex card rounded-custom shadow m-4 p-4">
            <h4>Ruolo: {{ role.name }}</h4>
            <h4>Dipartimento: {{ department.name }}</h4>
          </div>
        </template>
      </AppWizard>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../scss/abstracts/_variables.scss";
</style>

