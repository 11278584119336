<template>
  <div class="main-container d-flex flex-column vw-100 vh-100 p-4">
    <StandardModal
        v-if="showMissingModal"
        :size="'xl'"
        :modal_id="'missing_modal'"
        :title="'Casella non trovata'"
        :subtitle="'Puoi ricercarla, o sospendere la casella selezionata'"
        :decline_label="null"
        :lightLayout="false"
        :options="{keyboard: false, backdrop: 'static'}"
        @hidden="closeMissingModal">
          <div class="pt-4" style="min-height: 60vh;">
            <MissingWorksWizard
              :selectableData="missingWorkData"
              :open="showMissingModal"
              :selected="missingWorkData[0].prescription_test_id"
            >
            </MissingWorksWizard>
          </div>
      </StandardModal>
    <div class="header d-flex pb-4 flex-wrap">
      <div ref="logo" class="w-15 p-0 pe-4 logo  col-xxl-2 col-xl-3 col-md-3 col-sm-4 col-5">
        <div class="d-flex flex-column justify-content-between h-100">
          <Brand :width="180" withChat />
          <div class="d-flex flex-column">

            <!-- Logistic actions -->
            <template v-if="isCurrentDepartment(departments.LOGISTICS) || isCurrentDepartment(departments.AMMINISTRAZIONE) || (hasManagerRole && $route.query.read_only !== 'false')">
              <button type="button" class="btn btn-violet rounded-btn-custom shadow w-100">
                  <router-link to="/consulting/search" class="button-search-desktop d-flex justify-content-center">
                    <img class="me-2" src="@/assets/icons/search-white.svg" width="15" alt="search">Nuova ricerca
                  </router-link>
                  <router-link to="/consulting/search" class="button-search-mobile d-none d-flex justify-content-center">
                    <img src="@/assets/icons/search-white.svg" width="30" alt="search">
                  </router-link>
              </button>
            </template>

            <!-- Came back buttons -->
            <template v-else>
              <div class="d-flex">
                <template v-if="hasManagerRole && $route.query.from_iso === 'false'">
                  <ButtonBack :label="'dashboard'" class="flex-grow-1"/>
                </template>
                <template v-else>
                  <ButtonBack :label="'indietro'" class="flex-grow-1"/>
                </template>
              </div>
            </template>
            <!-- Simple search -->
            <template v-if="canSearch">
                <!-- Simple search -->
                <SimpleSearch class="mt-2" />
            </template>
          </div>
        </div>
      </div>

      <div ref="infoDetails" class="info-details d-flex pe-3 col-xl-8">
          <!-- Prescription details -->
          <div ref="prescriptionInfo" class="prescription-info w-100">
            <PrescriptionDetails
              :data="prescriptionDetails"
              :scanner="scanner"
              :is-digital-check-in="isCurrentDepartment(departments.ISO_PROGETTAZIONE)"
              :isCompleted="isCompleted"
            />
          </div>
      </div>

      <!-- Manager actions -->
      <template v-if="showActions">
        <div ref="moreActions" class="p-0 more-actions">
          <ManagerActions
            :class="{ 'loading': (Object.keys(prescriptionDetails).length === 0) }"
            :disabled="(Object.keys(prescriptionDetails).length === 0)"
            :is-digital-check-in="isCurrentDepartment(departments.ISO_PROGETTAZIONE)"
            :has-parent="hasParent"
            :has-children="hasChildren"
            :is-mobile-department="isMobileDepartment"
            :can-change-test="canChangeTest"
            @external="requestExternal"
            @open="openManagerActions"
            @action="executeAction"
          />
        </div>
      </template>

      <!-- Payments -->
      <template v-if="isCurrentDepartment(departments.LOGISTICS) || isCurrentDepartment(departments.AMMINISTRAZIONE)">
        <div class="w-15 p-0 ps-3 payments">
          <Payments
            :total="total"
            :show="showTotal"
            @openModal="readOpenPaymentModal"
          />
        </div>
      </template>
    </div>

    <div ref="mainBox" class="main-box d-flex h-80 me-5">
        <div class="info-box d-flex w-100 h-100">

          <!-- Prescription test -->
          <div ref="testWorkQueue" class="test-work-queue d-flex card rounded-only-left w-40 h-100 border-0 p-0">
            <div class="card-header d-flex align-items-center justify-content-center rounded-only-top-left bg-dark-violet text-white text-uppercase text-center">
              prove clinica
                <!-- Manager notes -->
                <NotesIcon id="notesIcon" color="white" class="ms-5" @click="openAllNotesModal()" />
                <template v-if="countManagerNotes !== 0">
                  <span class="badge badge-light pt-0">{{ countManagerNotes }}</span>
                </template>
                <span v-if="missingWorkData.length" class="cp" @click="openMissingModal">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" :fill="'white'" class="bi bi-inbox" viewBox="0 0 16 16"><path d="M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438L14.933 9zM3.809 3.563A1.5 1.5 0 0 1 4.981 3h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z"/></svg>
                    <span class="question-icon position-absolute">
                      <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" :fill="'white'" class="bi bi-question" viewBox="0 0 16 16"><path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/></svg>
                    </span>
                </span>
            </div>
            <div v-if="testsLoaded" class="card-body overflow-auto d-flex align-items-center justify-content-center p-0">
              <Tests
                :data="tests"
                @show="readShow"
              />
            </div>
          </div>

          <!-- History -->
          <div ref="testWorkQueuePhase" class="test-work-queue-phase d-flex card rounded-only-right w-60 h-100 border-0 p-0">
            <div class="card-header rounded-only-top-right bg-dark-violet text-white text-uppercase text-center">cronologia lavorazioni</div>
            <div class="card-body overflow-auto d-flex justify-content-center align-items-start p-0 border-start border-light border-4">
              <div ref="historyBox" class="history-box d-flex w-80 h-100 p-3 flex-column">
                <History
                  :current-prescription-test-id="prescriptionDetails.current_test_id"
                  :prescription-test-id="prescriptionTestId"
                  :item-to-show-slider="2"
                  :item-to-show-when-resize="itemToShowSliderWhenResize"
                />
              </div>
            </div>
          </div>

        </div>

        <!-- Tabs -->
        <template v-if="showTabIcon">
          <div class="orders-projects-chat d-flex flex-column rounded-only-bottom ms-4 w-35 h-100 position-relative overflow-hidden">
            <!-- Tab header -->
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button @click="setActiveTab('chat')" class="nav-link text-uppercase rounded-only-top active" id="chat-tab" data-bs-toggle="pill" data-bs-target="#chat" type="button" role="tab" aria-controls="chat" aria-selected="true">
                  <span class="me-1">chat</span>
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="setActiveTab('projects')" class="nav-link text-uppercase rounded-only-top d-flex align-items-center" id="projects-tab" data-bs-toggle="pill" data-bs-target="#projects" type="button" role="tab" aria-controls="projects" aria-selected="false">progetti</button>
              </li>
              <li class="nav-item" role="presentation">
                <button @click="setActiveTab('orders')" class="nav-link text-uppercase rounded-only-top" id="orders-tab" data-bs-toggle="pill" data-bs-target="#orders" type="button" role="tab" aria-controls="orders" aria-selected="false">ordini</button>
              </li>
            </ul>
            <!-- Content -->
            <div class="tab-content bg-white h-100 rounded-only-bottom rounded-only-top-right" id="pills-tabContent">
              <!-- Chat -->
              <div ref="chatRef" class="chat h-100 tab-pane fade" id="chat" role="tabpanel" aria-labelledby="chat-tab">
                <template v-if="tabActiveType === 'chat' && Object.keys(prescriptionWithChatChannel).length">
                  <Conversation
                    :prescription="prescriptionWithChatChannel"
                    :tab-active-type="tabActiveType"
                  />
                </template>
                <template v-else>
                  <Spinner />
                </template>
              </div>
              <!-- Project -->
              <div class="tab-pane fade h-100" id="projects" role="tabpanel" aria-labelledby="project-tab">
                <template v-if="tabActiveType === 'projects'">
                  <div class="d-flex flex-column rounded h-90 overflow-auto">
                    <ProjectsAndScene
                      :api-params="apiParams"
                      @preview="readAssetId"
                    />
                  </div>
                </template>
              </div>
              <!-- Orders -->
              <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                <template v-if="tabActiveType === 'orders'">
                  <div class="d-flex flex-column rounded h-100 overflow-auto">
                    <WarehouseRequests
                      :prescription-id="prescriptionId"
                    />
                  </div>
                </template>
              </div>
            </div>
            <!-- Close -->
            <div class="close position-absolute" @click="restore()">
              <img src="@/assets/icons/x-circle-fill-violet.svg" width="35" alt="x-circle-fill-violet-add.svg">
            </div>
          </div>
        </template>

        <!-- Half circle -->
        <template v-if="!showTabIcon">
          <HalfCircle
            @action="resize('chat')"
          />
        </template>

      </div>

  </div>

  <Empty v-if="showPaymentModal"
         :size="'xl'"
         :modal_id="paymentModalId"
         @hidden="closePaymentModal">
    <PaymentsDetails
      :data="listRows"
      :readOnlyMode="false"
      :isAddingItems="isAddingPaymentItems"
      @isAddingItems="onIsAddingItems"
      @total="syncTotal"
    />
  </Empty>

  <Empty v-if="reassign"
         :size="'md'"
         :modal_id="'reassignModal'"
         @hidden="closeReassignModal">
    <SpecialActions
      style="height: 400px"
      :title="'Seleziona il tecnico al quale riassegnare la lavorazione'"
      :modelNameSelect="technicianIdSelected"
      :placeholderNameSelect="'seleziona...'"
      :optionsSelect="departmentUsers"
      :notes="false"
      @selected="setTechnicianIdSelected"
      @clear="clearTechnicianSelected"
      @store="storeTechnician"
      @close="closeTechniciansModal"
    />
  </Empty>

  <!-- All notes modal -->
  <StandardModal v-if="showAllNotesModal"
         class="overflow-auto"
         size="lg"
         modal_id="historyNotes"
         title="Tutte le note"
         decline_label="chiudi"
         :lightLayout="false"
         @hidden="closeAllNotesModal">
    <template v-if="showNotes">
      <Notes
        :notes="notes.prescription_tests"
      />
    </template>
    <template v-else>
      <div class="d-flex w-100 h-100 justify-content-center align-items-center p-5">
        Nessuna nota presente
      </div>
    </template>
  </StandardModal>

  <!-- Vendors modal -->
  <Empty v-if="showVendorsModal"
        :size="'md'"
        :modal_id="'vendorsModal'"
        @hidden="closeExternalVendorsModal">
    <SpecialActions
      style="height: 400px"
      :title="'Seleziona il fornitore'"
      :modelNameSelect="vendorIdSelected"
      :placeholderNameSelect="'seleziona...'"
      :optionsSelect="vendors"
      @selected="setVendorIdSelected"
      @store="storeVendor"
      @close="closeVendorsModal"
    />
  </Empty>

</template>
<script>
import { onMounted, ref, reactive, computed, inject, nextTick, watch, provide } from "vue";
import {onBeforeRouteUpdate, useRoute, useRouter} from "vue-router";
import moment from "moment";
import { createDeviceDetector } from "next-vue-device-detector";
import { Modal } from "bootstrap";
export const device = createDeviceDetector();
import {useI18n} from "vue-i18n";
import {useStore} from "vuex";
import _head from "lodash/head";
import _sumBy from "lodash/sumBy";
import _forIn from "lodash/forIn";

import { externalPhases } from "@/use/repositories/testWorkQueuePhase/externalPhases";
import { fetchByRole as fetchVendors } from "@/use/repositories/users/fetchByRole";
import { changeState as changeStateUser } from '@/use/repositories/users/changeState';
import { fetchById as fetchPrescriptionById } from '@/use/repositories/prescriptions/fetchById';
import { fetchById as fetchTestWorkQueuePhaseById } from '@/use/repositories/testWorkQueuePhase/fetchById';
import { groupedByBridgesAndTooth } from "@/use/repositories/prescriptionRows/groupedByBridgesAndTooth";
import { fetchByDepartmentSlug } from "@/use/repositories/users/fetchByDepartmentSlug";
import { update as updateTestWorkQueuePhase } from '@/use/repositories/testWorkQueuePhase/update';
import { update as updateTestWorkQueueState } from '@/use/repositories/testWorkQueue/update';
import { fetchNotes } from '@/use/repositories/prescriptions/fetchNotes';
import { hideModal as destroyModal } from '@/use/modal/hide';
import { success } from "@/use/toast/success";
import { fetchById as fetchPrescriptionTestById } from '@/use/repositories/prescriptionTests/fetchById';
import { error } from "@/use/toast/error";
import { isCurrentDepartment } from "@/use/utilities/isCurrentDepartment";

import Brand from "@/components/Brand";
import Payments from '@/components/Consulting/Payments';
import PaymentsDetails from '@/components/Consulting/PaymentsDetails';
import ProjectsAndScene from "@/components/dashboard/ProjectsAndScene";
import WarehouseRequests from '@/components/Consulting/WarehouseRequests';
import PrescriptionDetails from '@/components/Consulting/PrescriptionDetails';
import Tests from '@/components/Consulting/Tests';
import History from "@/components/Consulting/History";
import ButtonBack from "@/components/general/ButtonBack";
import ManagerActions from "@/components/Manager/ManagerActions";
import Empty from "@/components/Modal/Empty";
import SpecialActions from "@/components/SpecialActions";
import Conversation from "@/components/chat/Conversation";
import NotesIcon from "@/components/Icons/Notes";
import Notes from "@/components/Notes";
import Spinner from "@/components/general/Spinner";
import { default as StandardModal } from '@/components/Modal/Standard';
import HalfCircle from "@/components/general/HalfCircle";
import MissingWorksWizard from "@/components/devices/MissingWorksWizard";

import SimpleSearch from "@/views/consulting/SimpleSearch";

export default {
  inheritAttrs: false, // This is what disables attribute inheritance
  name: "Dashboard",
  components: {
    Brand,
    Spinner,
    SimpleSearch,
    HalfCircle,
    History,
    Payments,
    PrescriptionDetails,
    Tests,
    WarehouseRequests,
    PaymentsDetails,
    ButtonBack,
    ManagerActions,
    Conversation,
    // Manager actions structure.
    Empty,
    SpecialActions,
    NotesIcon,
    Notes,
    StandardModal,
    ProjectsAndScene,
    MissingWorksWizard,
},
  setup() {
    const { states, departments, userStates } = inject("constants");
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const prescriptionDetails = ref({});
    const prescriptionWithChatChannel = ref({});
    const tests = ref([]);
    const prescriptionId = ref(route.params.prescription_id);
    const showTabIcon = ref(false);
    const itemToShowSliderWhenResize = ref(2);
    const i18n = useI18n();
    const localStates = {
      RESUME: "resume",
      RESUME_FAILED: "resume-failed",
      CHANGE_TECHNICIAN: "change-technician",
      ISO_CUSTOM: "iso-custom",
    }
    const prescriptionTestOrder = ref();
    const showAllNotesModal = ref(false);
    const notes = ref([]);
    const showAssets = ref(false);
    const isAddingPaymentItems = ref(false);
    const testsLoaded = ref(false);
    const apiParams = ref({
        prescription_id: prescriptionId,
        action: 'by_prescription'
        });
    // Manager actions.
    const departmentUsers = ref([]);
    const reassign = ref(false);
    const technicianIdSelected = ref(null);

    // Define modal id
    const paymentModalId = ref('payment');

    // Vendors
    const vendors = ref([]);
    const showVendorsModal = ref(false);
    const vendorIdSelected = ref(null);
    const closeExternalVendorsModal = () => {
      showVendorsModal.value = false;
      vendorIdSelected.value = null;
    }
    const closeVendorsModal = () => {
      destroyModal('vendorsModal');
    }
    const setVendorIdSelected = (vendorId) => {
      vendorIdSelected.value = vendorId;
    }
    const storeVendor = () => {
      externalPhases(
        parseInt(route.query.test_work_queue_phase_id),
        isMobile,
        vendorIdSelected.value,
      ).then(async () => {
        updateTestWorkQueuePhase({
            source_ids: [parseInt(route.query.test_work_queue_phase_id)],
            state: states.COMPLETED,
            action: "update",
          }).then(async () => {
            changeStateUser(userStates.AVAILABLE).then(async () => {
            closeVendorsModal();
            return router.push({ name: "Checkin" });
          });
        });
      });
    }
    const requestExternal = () => {
        // Get vendors.
        fetchVendors('vendor', 'with_test_work_queue_phases').then((response) => {
          vendors.value = response.users.map((item) => {
            return {
              value: item.id,
              label: `${item.first_name} ${item.last_name}`,
            };
          });
          showVendorsModal.value = true;
        });
    }

    // Mobile Pages.
    const home = ref(true);
    const details = ref(false);
    const orders = ref(false);
    const chat = ref(false);
    const isMobile = ref(device.mobile);
    const isMobileDepartment = store.state.mainRole.team.name === departments.ISO_MOBILE;
    const scene = reactive({
      id: null,
      modal_id: null,
      title: null,
      subtitle: null,
      decline_label: null,
      temporary_url: null,
    });
    const isCompleted = computed(() => {
      return tests.value.every((test) => test.state_slug === states.COMPLETED);
    });
    const showPaymentModal = ref(false);
    const prescriptionTestId = ref(null);
    const listRows = ref([]);
    const total = ref(0);
    const showTotal = ref(false);
    const hasManagerRole = ref(store.state.user.roles.includes('manager'));
    const showActions = computed(() => {
      return (hasManagerRole.value && route.query.read_only === 'false');
    });
    const tabActiveType = ref(null);
    const showNotes = computed(() => {
      return notes.value.prescription_tests.some(
        (prescriptionTest) => prescriptionTest.laboratory_notes.length > 0
      );
    });

    const canChangeTest = computed(() => {
      return ! [
      'iso-in-changed-test',
      'manager-changed-test',
      ].includes(testWorkQueuePhaseData.value?.test_work_queue?.state?.slug);
    })

    const canSearch = computed(() => {
      return hasManagerRole.value || isCurrentDepartment(departments.LOGISTICS) || isCurrentDepartment(departments.AMMINISTRAZIONE);
    });

    const onIsAddingItems = (value) => {
      isAddingPaymentItems.value = value;

      // Find payment modal on dom
      const paymentModal = document.getElementById(paymentModalId.value);
      if (!paymentModal) return;

      // Get modal instance
      const modalInstance = Modal.getInstance(paymentModal);

      // Toggle properties
      if (isAddingPaymentItems.value === true) {
          modalInstance._config.backdrop = 'static';
          modalInstance._config.keyboard = false;
      } else {
          modalInstance._config.backdrop = true;
          modalInstance._config.keyboard = true;
      }
    }

    // Chat.
    const showChat = ref(false);
    //const showHistoryChat = ref(false);
    const loadingMessages = ref(true);

    const setActiveTab = (nameTab) => {
      tabActiveType.value = nameTab;
    }
    // Preselect project tab.
    setActiveTab('chat');
    const testWorkQueuePhaseData = ref(null);
    // Nodes.
    const testWorkQueuePhase = ref(null);
    const testWorkQueue = ref(null);
    const historyBox = ref(null);
    const mainBox = ref(null);
    const infoDetails = ref(null);
    const prescriptionInfo = ref(null);
    const logo = ref(null);
    const moreActions = ref(null);
    const chatRef = ref(null);
    const countManagerNotes = ref(0);
    const managerNotesHaveChanged = computed(() => {
      return store.state.managerNotesHaveChanged;
    });
    const hasChildren = ref(null);
    const hasParent = ref(null);
    // Digital check-in info.
    const scanner = ref(null);

    onBeforeRouteUpdate(async (to) => {
      // Reload.
      window.location.replace(to.href);
    });

    watch(() => prescriptionTestId.value, (value) => {
      if (value !== null) {
        fetchPrescriptionTestById(value, 'with_intraoral_scanner').then((response) => {
          scanner.value = response.prescriptionTest.intraoral_scanner;
          let twqStateSlug = response.prescriptionTest.test_work_queue.state.slug;

          if (twqStateSlug !== states.IN_THE_WORKS) {
            updateTestWorkQueuePhase({
              source_ids: [route.query.test_work_queue_phase_id],
              state: states.IN_THE_WORKS,
              action: "update",
            }).then(async () => {
            });
          }
        }).catch((response) => {
          _forIn(response, function(value) {
            value.forEach((message) => {
              error(message);
            })
          });
        });
      }
    })

    onMounted(() => {
      if (window.innerWidth < 1400) {
        showTabIcon.value = true;
        setDefaultTab('chat');
      }
    })

    onMounted(async () => {
      if (route.query.test_work_queue_phase_id) {
        fetchTestWorkQueuePhaseById(route.query.test_work_queue_phase_id, "with_iso_details").then(async (response) => {
          testWorkQueuePhaseData.value = response.testWorkQueuePhase;
          hasChildren.value = response.testWorkQueuePhase.children_test_work_queue_phases.length;
          hasParent.value = response.testWorkQueuePhase.parent_test_work_queue_phases.length;
      });
      }

      // Get count notes.
      countAllNotes();

      console.time("HEADER DATA LOAD IN:");
      // Fetch header data.
      fetchPrescriptionById(prescriptionId.value, 'with_basic_data', false).then((response) => {
        prescriptionTestId.value = response.prescription?.current_test_id;
        let prescription = response.prescription;
        prescriptionDetails.value = {
          id: prescription.id,
          customer_name: prescription.customer.first_name,
          customer_last_name: prescription.customer.last_name,
          clinic_name: prescription.clinic.name,
          doctor_name: prescription.doctor.first_name,
          doctor_surname: prescription.doctor.last_name,
          number_text: prescription.number_text,
          created_at: moment(prescription.created_at).format("DD/MM/YYYY"),
          prescription_row_name: _head(prescription?.prescription_rows)?.name,
          plant_type: prescription.plant_type,
          material: prescription.material,
          bruxism: prescription.customer.bruxism ? "si" : "no",
          color_code: prescription.color ? prescription.color.code : 'Nessuna tinta presente',
          elements: groupedByBridgesAndTooth(prescription.prescription_rows),
          prescription_rows: prescription.prescription_rows,
          current_test_id: prescription.current_test_id,
        }
        console.timeEnd("HEADER DATA LOAD IN:");
      });

      // Fetch payments.
      if (isCurrentDepartment(departments.LOGISTICS) || isCurrentDepartment(departments.AMMINISTRAZIONE)) {
        console.time("PAYMENTS DATA LOAD IN:");
        fetchPrescriptionById(prescriptionId.value, 'with_receipts', false).then((response) => {
          listRows.value = response.prescription.receipts;
          calculateTotal(response.prescription.receipts);
          console.timeEnd("PAYMENTS DATA LOAD IN:");
        });
      }

      console.time("PRESCRIPTION TESTS DATA LOAD IN:");
      // Fetch prescription tests.
      fetchPrescriptionById(prescriptionId.value, 'with_prescription_tests', false).then((response) => {
        transformDataTests(response.prescription.prescription_tests);
        console.timeEnd("PRESCRIPTION TESTS DATA LOAD IN:");
      });

      console.time("CHAT CHANNEL DATA LOAD IN:");
      // Fetch chat channel.
      fetchPrescriptionById(prescriptionId.value, 'with_chat_channel', false).then((response) => {
        prescriptionWithChatChannel.value = {
          id: response.prescription.id,
          chat_channel: response.prescription.chat_channel,
        }
        console.timeEnd("CHAT CHANNEL DATA LOAD IN:");
      });

      // Automatic show chat.
      if (route.query.show_chat === 'true') {
        // Restore style.
        resize('chat');
      }
    });

    watch(() => managerNotesHaveChanged.value, (haveChanges) => {
      if (haveChanges) {
        countAllNotes();
      }
    });

    const calculateTotal = (receipts) => {
      receipts.forEach((item) => {
        item.totalAmount = +item.price * item.quantity;
      });

      total.value = _sumBy(receipts, 'totalAmount');
      showTotal.value = true;
    }

    const transformDataTests = (prescriptionTests) => {
      prescriptionTests.forEach((test) => {
        let testWorkQueue = test.test_work_queue;

        if (testWorkQueue !== null) {
          const isCurrentTest = prescriptionDetails.value?.current_test_id === test.id;

          if (isCurrentTest && test.device) {
            missingWorkData.value.push({
              id: null,
              prescription_test_id: prescriptionDetails.value.current_test_id,
              name: prescriptionDetails.value.number_text,
              first_name:  prescriptionDetails.value.customer_name,
              last_name:  prescriptionDetails.value.customer_last_name,
              delivery_date: formatDate(testWorkQueue?.delivery_date),
              device_id: test.device?.id
            });
          }

          return tests.value.push({
            id: test.id,
            code: test.code,
            state_slug: testWorkQueue.state.slug,
            name: test.name,
            delivery_date: formatDate(testWorkQueue.delivery_date),
            created_at: formatDate(testWorkQueue.created_at),
            checkout_date: testWorkQueue.shipping_codes.length ? formatDate(_head(testWorkQueue.shipping_codes).created_at) : "",
            shipping_codes: testWorkQueue.shipping_codes,
            selected: isCurrentTest,
            media: testWorkQueue.media,
            appointmentDate: test.date?.start_at,
            is_current_test: isCurrentTest,
            test_work_queue: testWorkQueue,
          });
        }

        // Skipped test.
        return tests.value.push({
          id: test.id,
          code: test.code,
          state_slug: test.state !== null ? test.state.slug : 'without-state',
          name: test.name,
          delivery_date: null,
          appointmentDate: test.date?.start_at,
        });
      });

      prescriptionTestOrder.value = tests.value.map((test) => {
        return {
          id: test.id,
          code: test.code,
          appointmentDate: test.appointmentDate,
        };
      });

      testsLoaded.value = true;
    };

    const formatDate = (dateString) => {
      return moment(dateString).format("DD/MM/YYYY HH:mm");
    }

    const readShow = (id) => {
      prescriptionTestId.value = id;
    }

    const setDefaultTab = (tabName) => {
      setActiveTab(tabName);
      if (tabName === 'projects') {
        nextTick(() => {
          // Trigger click on chat tab.
          document.querySelector('#project-tab').click();
        });
      }
      if (tabName === 'chat') {
        nextTick(() => {
          chatRef.value.classList.add("show", "active");
        });
      }
    }

    const resize = (tabName) => {
      itemToShowSliderWhenResize.value = 1;

      testWorkQueuePhase.value.style.width = '50%';
      testWorkQueue.value.style.width = '50%';
      historyBox.value.style.width = '100%';
      mainBox.value.style.width = '100%';

      showTabIcon.value = true;
      setDefaultTab(tabName);
    }

    const restore = () => {
      itemToShowSliderWhenResize.value = 2;

      testWorkQueuePhase.value.style.width = '60%';
      testWorkQueue.value.style.width = '40%';
      historyBox.value.style.width = '80%';
      mainBox.value.style.width = 'unset';

      showTabIcon.value = false;
    }

    const closePaymentModal = (value) => {
      // Close payment modal.
      showPaymentModal.value = value;
    }

    const readOpenPaymentModal = () => {
      // Open payment modal.
      showPaymentModal.value = true;
    }

    // Manager.
    const openManagerActions = () => {
      let moreInfo = document.getElementsByClassName('more-info');
      let prescriptionRow = document.getElementsByClassName('prescription-row');
      let customer = document.getElementsByClassName('customer');

      // Toggle visibility element.
      _head(moreInfo).classList.toggle('d-none');

      if (infoDetails.value.style.width === '50%') {
        // Restore size.
        //infoDetails.value.style.width = '85%';
        _head(prescriptionRow).classList.add('border-end');
        _head(prescriptionRow).classList.add('width-custom');
        _head(customer).classList.add('width-custom');
      } else {
        // Resize.
        //infoDetails.value.style.width = '50%';
        _head(prescriptionRow).classList.remove('width-custom');
        _head(customer).classList.remove('width-custom');
        _head(prescriptionRow).style.width = "(100% / 2)";
        _head(customer).style.width = "(100% / 2)";
        _head(prescriptionRow).classList.remove('border-end');
      }

      if (moreActions.value.classList.contains('col-xl-6')) {
        moreActions.value.classList.add('col-xl-2', 'col-lg-2', 'col-md-3', 'col-sm-4', 'col-5');
        moreActions.value.classList.remove('col-xl-6', 'col-12');
        infoDetails.value.classList.remove('col-xl-4', 'col-lg-9', 'col-md-8', 'col-sm-12');
        infoDetails.value.classList.add('col-xl-8', 'col-lg-8', 'col-md-9', 'col-sm-8');
      } else {
        moreActions.value.classList.remove('col-xl-2', 'col-lg-2', 'col-md-3', 'col-sm-4', 'col-5');
        moreActions.value.classList.add('col-xl-6', 'col-12');
        infoDetails.value.classList.remove('col-xl-8', 'col-lg-8', 'col-md-9', 'col-sm-12');
        infoDetails.value.classList.add('col-xl-4', 'col-lg-9', 'col-md-8', 'col-sm-8');
      }
    }

    const redirectOnDynamicIsoInView = async (slug) => {
      await router.push({
        name: "IsoInState",
        params: {
          state_slug: slug,
        },
        query: {
          test_work_queue_id: testWorkQueuePhaseData.value.test_work_queue.id,
          test_work_queue_phase_id: route.query.test_work_queue_phase_id,
          internal_phase_id: testWorkQueuePhaseData.value.internal_phase.id,
          prescription_test_id: testWorkQueuePhaseData.value.test_work_queue.prescription_test.id,
          test_id: testWorkQueuePhaseData.value.test.id,
          laboratory_list_row_id: testWorkQueuePhaseData.value.test.laboratory_list_row_id,
          prescription_id: route.params.prescription_id,
          prescription: prescriptionDetails.value?.number_text,
          delivery_date: formatDate(prescriptionDetails.value?.test_work_queue?.delivery_date),
        },
      });
    };

    const executeAction = (actionType) => {
      switch (actionType) {
        // Local states.
        case localStates.RESUME:
          updateTestWorkQueuePhase({
            source_ids: [route.query.test_work_queue_phase_id],
            state: states.ASSIGNED,
            action: "update",
          }).then(() => {
            if (testWorkQueuePhaseData.value.test_work_queue.state.slug === states.ON_HOLD) {
              updateTestWorkQueueState({
                id: route.query.test_work_queue_id,
                state_slug: states.IN_THE_WORKS,
                action: "update_state"
              });
            }

            setTimeout(() => {
              success(i18n.t('Internal phase successfully changed'));
            }, 2000);

            return router.push({ name: "ManagerDashboard" });
          });
          break;
        case localStates.CHANGE_TECHNICIAN:
          fetchByDepartmentSlug(store.state.mainRole.team.name).then((response) => {
            departmentUsers.value = response.usersDepartment.users.map((user) => {
              return {
                value: user.id,
                label: `${user.first_name} ${user.last_name}`,
              };
            });

            reassign.value = true;
          });
          break;
        case localStates.RESUME_FAILED:
          updateTestWorkQueuePhase({
            source_ids: [route.query.test_work_queue_phase_id],
            state: states.PENDING,
            action: "update",
          }).then(() => {
            setTimeout(() => {
              success(i18n.t('Job resumed successfully'));
            }, 2000);
            return router.push({ name: "ManagerDashboard" });
          });
          break;
        case localStates.ISO_CUSTOM:
          redirectOnDynamicIsoInView(localStates.ISO_CUSTOM);
          break;
        // States.
        case states.COMPLETED:
          updateTestWorkQueuePhase({
            source_ids: [route.query.test_work_queue_phase_id],
            state: states.COMPLETED,
            action: "update",
            }).then(() => {
              if (isCurrentDepartment(departments.ISO_PROGETTAZIONE)) {
                success(i18n.t('Dental impression successfully approved!'));
              }

              if (hasChildren.value && testWorkQueuePhaseData.value?.test_work_queue?.state?.slug === states.ON_HOLD) {
                updateTestWorkQueueState({
                  id: testWorkQueuePhaseData.value.test_work_queue_id,
                  state_slug: states.IN_THE_WORKS,
                  action: "update_state"
                });

                return router.push({ name: "ManagerDashboard" });
              } else if (! hasChildren.value && hasParent.value) {
                updateTestWorkQueueState({
                    id: testWorkQueuePhaseData.value.test_work_queue_id,
                    state_slug: states.COMPLETED,
                    action: "update_state"
                  });
              }

              changeStateUser('available').then(async () => {
                await router.push({ name: "Checkin" });
              });
          });
          break;
        case states.MANAGER_CHANGED_TEST:
        case states.MANAGER_REASSIGNED:
        case states.MANAGER_FAILED:
        case states.ON_HOLD:
        case states.ISO_FAILED:
        case states.ISO_IN_CHOOSE_FORK:
        case states.ISO_IN_CHANGED_TEST:
        case states.ISO_IN_CHANGED_DATE:
        case states.ISO_REASSIGNED:
          redirectOnDynamicIsoInView(actionType);
          break;
      }
    }

    // Reassign data.
    const closeReassignModal = () => {
      reassign.value = false;
      technicianIdSelected.value = null;
    }
    const setTechnicianIdSelected = (technicianId) => {
      technicianIdSelected.value = technicianId;
    }
    const clearTechnicianSelected = () => {
      technicianIdSelected.value = null;
    }
    const storeTechnician = () => {
      updateTestWorkQueuePhase({
        source_ids: [route.query.test_work_queue_phase_id],
        user_id: technicianIdSelected.value,
        action: "attach_user",
      })
      .then(() => {
        setTimeout(() => success(i18n.t('Technician changed successfully')), 2000);
        destroyModal('reassignModal');

        return router.push({ name: "ManagerDashboard" });
      });
    }
    const closeTechniciansModal = () => {
      // Destroy modal instance.
      destroyModal('reassignModal');
    }

    const syncTotal = (value) => {
      total.value = value;
    }

    const openAllNotesModal = () => {
      // Fetch all notes by prescription id.
      fetchNotes(prescriptionId.value).then((response) => {
        // Reverse prescription test order.
        response.notes.prescription_tests = response.notes.prescription_tests.reverse();

        notes.value = response.notes;
        showAllNotesModal.value = true;
      });
    }

    const countAllNotes = () => {
      countManagerNotes.value = 0;
      fetchNotes(prescriptionId.value, false).then((response) => {
        response.notes.prescription_tests.forEach((prescriptionTest) => {
          if (prescriptionTest.prescription_id === parseInt(prescriptionId.value)) {
            countManagerNotes.value += prescriptionTest.laboratory_notes.length;
          }
        });
        store.dispatch("setManagerNotesHaveChanged", false);
      });
    }

    const closeAllNotesModal = () => { showAllNotesModal.value = false }

    provide('prescriptionTestOrder', prescriptionTestOrder);

    const missingWorkData = ref([]);
    const showMissingModal = ref(false);
    const closeMissingModal = () => {
      destroyModal('missing_modal');
      showMissingModal.value = false;
    }
    const openMissingModal = () => {
      showMissingModal.value = true;
    }

    return {
      missingWorkData,
      showMissingModal,
      closeMissingModal,
      openMissingModal,
      prescriptionDetails,
      tests,
      readShow,
      prescriptionId,
      resize,
      showTabIcon,
      restore,
      itemToShowSliderWhenResize,
      home,
      details,
      chat,
      orders,
      chatRef,
      isMobile,
      scene,
      isCompleted,
      showPaymentModal,
      closePaymentModal,
      readOpenPaymentModal,
      prescriptionTestId,
      listRows,
      total,
      showTotal,
      hasManagerRole,
      openManagerActions,
      showActions,
      executeAction,
      departmentUsers,
      reassign,
      closeReassignModal,
      technicianIdSelected,
      setTechnicianIdSelected,
      clearTechnicianSelected,
      storeTechnician,
      closeTechniciansModal,
      showChat,
      loadingMessages,
      setActiveTab,
      syncTotal,
      tabActiveType,
      prescriptionWithChatChannel,
      testWorkQueuePhase,
      testWorkQueue,
      historyBox,
      mainBox,
      infoDetails,
      prescriptionInfo,
      logo,
      moreActions,
      openAllNotesModal,
      showAllNotesModal,
      closeAllNotesModal,
      notes,
      showNotes,
      showAssets,
      countManagerNotes,
      paymentModalId,
      onIsAddingItems,
      isAddingPaymentItems,
      apiParams,
      scanner,
      isCurrentDepartment,
      isMobileDepartment,
      departments,
      hasChildren,
      hasParent,
      testWorkQueuePhaseData,
      requestExternal,
      showVendorsModal,
      closeExternalVendorsModal,
      closeVendorsModal,
      setVendorIdSelected,
      vendorIdSelected,
      storeVendor,
      vendors,
      canChangeTest,
      testsLoaded,
      canSearch
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/scss/abstracts/_variables.scss";
  .close:hover, #notesIcon { cursor: pointer; }
  .card-header:first-child { border-radius: unset; }
  .responsive {
    width: 100%;
    height: auto;
  }
  a {
    color: unset !important;
    text-decoration: none !important;
  }
  .nav-link, .nav-link:hover {
    color: $dark_violet;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #7E6492;
  }
  .close {
    right: 2px;
    top: 3px;
  }
  .test-work-queue-phase,
  .test-work-queue {
    border-radius: unset;
  }
  .loading {
    opacity: 0.5;
    pointer-events: none;
  }
  @media screen and (max-width: 1540px) {
    .test-work-queue-phase { width: 50%; }
    .test-work-queue { width: 50%; }
  }
  @media screen and (max-width: 1410px) {
    .button-search-desktop { display: none !important; }
    .button-search-mobile { display: block !important; }
  }
  @media screen and (max-width: 1200px) {
    .close { display: none !important; }
    .main-box { flex-direction: column !important; }
    .history-box, .main-box { width: 100% !important; }
    .test-work-queue-phase { width: 60% !important; }
    .orders-projects-chat { display: block !important; margin-left: unset !important; margin-top: 1em; width: 100% !important; }
    .tab-content { border-top-right-radius: 1rem !important; }
   /*  .info-details, .payments { width: 100% !important; } */
    .payments { padding-left: unset !important; margin-top: 1em !important; }
    .main-container { height: unset !important; }
    .main-box { height: 1000px !important; }
  }
  @media screen and (max-width: 760px) {
    .info-box { flex-direction: column !important; }
    .test-work-queue-phase { display: none !important; }
    .test-work-queue { width: 100% !important; border-top-right-radius: 1rem !important; border-bottom-right-radius: 1rem !important; }
    .test-work-queue > .card-header { border-top-left-radius: 1rem !important; border-top-right-radius: 1rem !important; }
  }
</style>
